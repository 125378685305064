<template>
  <div class="questions-container">
    <section
      v-for="(question, questionIndex) in questions"
      :key="question.questionId"
      :ref="question.questionId"
      class="question"
    >
      <div class="form-row">
        <div class="order form-input-10">
          <label class="input-header">Question #</label>
          <PassportDropdown
            v-model="question.order"
            class="short cms-input"
            :options="orderOptions"
            open-direction="bottom"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            @input="changeOrder(question)"
          />
        </div>
        <div class="form-input-90">
          <label class="input-header">Question Text*</label>
          <PassportInput
            v-model="question.questionText"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            placeholder="Enter your question text…"
            required
            class="cms-input"
            @select="handleQuestionTypeChange(question, questionIndex)"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-input-100">
          <div v-for="(answer, answerIndex) in question.questionOptions" :key="answer.answerId" class="answer-field">
            <div class="form-input-100">
              <label v-if="answerIndex <= 1" class="input-header">Answer Option {{ answerIndex + 1 }}*</label>
              <label v-else class="input-header">Answer Option {{ answerIndex + 1 }}</label>
              <PassportInput
                v-model="answer.answerText"
                class="form-input"
                type="text"
                required
                showCount
                :maxlength="100"
              />
            </div>
            <div>
              <PassportButton
                :disabled="answerIndex <= 1"
                variant="text danger"
                class="remove-button"
                @click.prevent="removeAnswerOption(answer.answerId, answerIndex, questionIndex)"
              >
                Remove <span class="button-icon"><IconX /></span>
              </PassportButton>
            </div>
            <div class="order form-input-10">
              <label class="input-header">Result Lead</label>
              <PassportDropdown
                v-model="answer.answerLead"
                class="short cms-input"
                :options="leadOptions"
                open-direction="bottom"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <PassportButton variant="text" class="add-button" @click.prevent="addAnswerOption(questionIndex)">
          Add Answer Option <span class="button-icon"><IconPlus /></span>
        </PassportButton>
        <PassportButton
          :disabled="questionIndex === 0"
          variant="text danger"
          class="remove-button"
          @click.prevent="removeQuestion(question.questionId, questionIndex)"
        >
          Delete Question <span class="button-icon"><IconX /></span>
        </PassportButton>
      </div>
    </section>
    <PassportButton variant="text" class="add-button" @click.prevent="addQuestion">
      Add Question <span class="button-icon"><IconPlus /></span>
    </PassportButton>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PassportInput from '@/components/PassportInput.vue';
import PassportDropdown from '@/components/PassportDropdown.vue';
import PassportButton from '@/components/PassportButton.vue';
import IconPlus from '@/assets/icons/plus.svg';
import IconX from '@/assets/icons/x_icon.svg';

// Icons

export default {
  components: {
    PassportInput,
    PassportDropdown,
    PassportButton,
    IconX,
    IconPlus,
  },
  data() {
    return {
      selectedQuestionType: null,
    };
  },
  props: {
    existingQuestions: {
      type: Array,
    },
    existingResults: {
      type: Array,
    },
  },
  computed: {
    questions: {
      get() {
        return this.existingQuestions || [];
      },
      set(value) {
        this.$emit('update:existingQuestions', value);
      },
    },
    sortedQuestions() {
      return this.sortQuestions(this.questions);
    },
    orderOptions() {
      const options = [];
      for (let i = 0; i < this.questions.length; i += 1) {
        options.push(i + 1);
      }
      return options;
    },
    leadOptions() {
      const resultLead = [];

      for (let i = 0; i < this.questions[0].questionOptions.length; i += 1) {
        resultLead.push(i + 1);
      }
      return resultLead;
    },
  },
  mounted() {
    this.answerGenerator();
  },
  methods: {
    fileRemovedAction() {},
    fileAddedAction() {},
    answerGenerator() {
      let questionIndex = 0;
      while (questionIndex <= 1) {
        this.questions[0].questionOptions.push(this.generateAnswers(this.questions[0].questionId));
        questionIndex += 1;
      }
    },
    sortQuestions(questions) {
      return questions.slice().sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
    },
    addAnswerOption(questionIndex) {
      // console.log(this.questions[questionIndex].questionOptions.length);
      if (this.questions[questionIndex]) {
        this.questions[questionIndex].questionOptions.push(
          this.generateAnswers(this.questions[questionIndex].questionId),
        );
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${questionIndex} was not found`,
        });
      }
    },
    removeAnswerOption(answerId, answerIndex, questionIndex) {
      if (this.questions[questionIndex].questionOptions[answerIndex]) {
        this.questions[questionIndex].questionOptions.splice(answerIndex, 1);
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The answer option with id ${answerId} was not found`,
        });
      }
    },
    removeQuestion(questionId, index) {
      if (this.questions[index]) {
        // update array to avoid any gaps in numbers. ex 1 3 5 -> 1 2 3
        const localQuestions = this.questions.filter((q) => q.questionId !== questionId);
        const sortedQuestions = this.sortQuestions(localQuestions);
        for (let i = 0; i < sortedQuestions.length; i += 1) {
          const questionIndex = localQuestions.findIndex(
            (question) => question.questionId === sortedQuestions[i].questionId,
          );
          localQuestions[questionIndex].order = i + 1;
        }
        this.questions = [...localQuestions];
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${index} was not found`,
        });
      }
    },
    addQuestion() {
      const questionId = `${uuidv4()}`;
      this.questions.push({
        questionId,
        text: '',
        type: '',
        order: this.questions.length + 1,
        questionOptions: this.generateAnswers(questionId, 2),
        questionAnswers: [],
      });
    },
    changeOrder(changedQuestionCard) {
      const reorderedQuestions = [...this.sortedQuestions];
      const newIndex = changedQuestionCard.order - 1;
      const oldIndex = reorderedQuestions.findIndex(
        (question) => question.questionId === changedQuestionCard.questionId,
      );
      if (oldIndex > -1) {
        // Remove item from array
        reorderedQuestions.splice(oldIndex, 1);
        // Insert item at new index
        reorderedQuestions.splice(newIndex, 0, changedQuestionCard);
      }
      // console.log(reorderedQuestions);
      // take the indicies of the reordered array and assign those to the order key on the main array
      const localQuestions = [...this.questions];
      for (let i = 0; i < reorderedQuestions.length; i += 1) {
        // Find the reordered array element find where it is in the main array to update it's order key
        const questionIndex = localQuestions.findIndex(
          (question) => question.questionId === reorderedQuestions[i].questionId,
        );
        // assign as current reordered array key + 1 for a start at 1
        localQuestions[questionIndex].order = i + 1;
      }
      this.questions = [...localQuestions];
    },
    handleQuestionTypeChange(question, questionIndex) {
      this.$nextTick().then(() => {
        if (question && questionIndex !== undefined) {
          // Update questionTypeId
          question.questionTypeId = question.type.id;
          // Clear question options and answers on type change
          if (question.questionOptions && question.questionOptions.length) {
            this.questions[questionIndex].questionOptions = [];
          }
          if (question.questionAnswers && question.questionAnswers.length) {
            this.questions[questionIndex].questionAnswers = [];
          }

          // Append empty question objects based on type
          // if (question.type.key === 'open answer' || question.type.key === 'fill-in-the-blank') {
          //   this.questions[questionIndex].questionAnswers.push(
          //     this.generateAnswers(question.type.key, question.questionId),
          //   );
          // } else {
          this.questions[questionIndex].questionOptions = this.generateAnswers(
            question.type.key,
            question.questionId,
            2,
          );
          // }
        }
      });
    },
    // Returns single answer by default or many answers if question type is multiple choice
    /**
     * @param {string} questionType
     * @param {string} questionId
     * @param {number} _count
     * @returns {object|Array}
     */
    generateAnswers(questionId, _count = 1) {
      const answers = [];
      let count = _count;

      while (count) {
        answers.push({
          answerText: '',
          answerId: `${uuidv4()}`,
          answerLead: '1',
        });
        count -= 1;
      }
      return answers.length === 1 ? answers[0] : answers;
    },
    /**
     * Validate checkboxes and radio buttons before submission
     * @returns {boolean}
     */
    validateQuiz() {
      let isValid = true;
      this.questions.forEach((question) => {
        if (question.type.key === 'multiple choice') {
          const answerInput = this.$refs[question.questionId][0].querySelector('input[type="checkbox"]');
          if (!question.questionOptions.some((option) => option.isAnswer)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'At least one answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        } else if (question.type.key === 'single answer') {
          const answerInput = this.$refs[question.questionId][0].querySelector('input[type="radio"]');
          if (!question.questionOptions.some((option) => option.isAnswer)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'One answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        }
      });
      return isValid;
    },
    /**
     * Update an element's custom validity with a message.
     */
    updateElementValidity(element, message = '') {
      element.setCustomValidity(message);
    },
    /**
     * Finds input element based on questionId and className and updates it to valid.
     */
    updateInputsValid(questionId, className) {
      this.updateElementValidity(this.$refs[questionId][0].getElementsByClassName(className)[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-container {
  display: block;
  width: 100%;
  .question {
    padding: 40px;
    margin-bottom: 40px;
    background-color: rgba(#fff, 0.15);
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-row,
    .form-row-double {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // &:not(:last-child) {
      //   margin-bottom: 20px;
      // }

      .form-input {
        width: 100%;
      }

      .form-input-100 {
        width: 100%;
      }

      .form-input-90 {
        width: 90%;
      }

      .order {
        margin-right: 30px;
      }
      .form-input-10 {
        width: 20%;
      }
    }
    .wrap-row {
      flex-wrap: wrap;
    }
    .input-header {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    .subtext {
      margin: 10px 0 0;
      margin-bottom: 15px;
      font-size: 12px;
      opacity: 0.5;
    }
    ::v-deep .wrapper {
      margin-top: 0.5em;
    }
    ::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
      height: 53px;
      margin-top: 0.5em;
    }

    .answer-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1em;
      margin-bottom: 25px;
      .answer-checkbox {
        margin-right: 30px;
      }
      .form-input {
        margin-bottom: -15px;
      }
      // .remove-button {
      //   // margin-top: 2em;
      //   // margin-left: 15px;
      // }
    }

    .matching {
      flex-wrap: wrap;
      label {
        width: 100%;
      }

      .match-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;

        .form-input-45 {
          width: 45%;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .add-button {
    .button-icon {
      width: 20px;
      height: 20px;
    }
  }

  .add-button,
  .remove-button {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    letter-spacing: 0.7px;
    cursor: pointer;
    background: none;
    border: none;
  }

  .button-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 15px;
  }

  .input-spacing {
    margin-left: 20px;
  }
}
</style>
