<template>
  <div class="questions-container">
    <section v-for="(question, index) in questions" :key="question.resultId" :ref="question.resultId" class="question">
      <div class="form-row">
        <div class="order form-input-10">
          <label class="text--input-header">Result #</label>
          <PassportInput
            disabled
            :placeholder="index + 1"
            class="short cms-input"
            :allow-empty="false"
            :show-labels="false"
            @input="changeOrder(question)"
          />
        </div>
        <div class="form-input-90">
          <label class="text--input-header">Result Title*</label>
          <PassportInput
            v-model="question.resultTitle"
            :allow-empty="false"
            :show-labels="false"
            placeholder="Enter your result title..."
            required
            class="cms-input"
            showCount
            :maxlength="200"
          />
        </div>
      </div>
      <label class="text--input-header">Result description*</label>
      <PassportInput
        v-model="question.resultDescription"
        placeholder="Enter your description for this result…"
        class="form-input"
        type="text"
        required
        showCount
        :maxlength="500"
      />
      <div class="upload-container">
        <span class="input-header"> Result Image* </span>
        <div class="results-upload">
          <PassportImageUploader
            ref="file1"
            class="image-upload"
            :key="`instance${index}`"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="10000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
            :value="getAssetUrl(question.resultImage)"
            @fileAdded="fileAddedAction(index)"
            @fileRemoved="fileRemovedAction(index)"
          />
        </div>
        <!-- {{ question.resultImage }} -->
        <span class="input-instructions">
          Upload an image for this result. Accepted file types: PNG, JPG, JPEG. File size limit: 10MB
        </span>
      </div>

      <PassportButton
        v-if="index > 1"
        variant="text danger"
        class="remove-button"
        @click.prevent="removeQuestion(question.resultId, index)"
      >
        Remove Result <span class="button-icon"><IconX /></span>
      </PassportButton>
    </section>

    <PassportButton variant="text" class="add-button" @click.prevent="addResult">
      Add Result <span class="button-icon"><IconPlus /></span>
    </PassportButton>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PassportInput from '@/components/PassportInput.vue';
import IconPlus from '@/assets/icons/plus.svg';
import IconX from '@/assets/icons/x_icon.svg';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
import PassportButton from '@/components/PassportButton.vue';

// Icons

export default {
  components: {
    PassportInput,
    PassportButton,
    PassportImageUploader,
    IconX,
    IconPlus,
  },
  data() {
    return {};
  },
  props: {
    existingResults: {
      type: Array,
    },
  },
  computed: {
    questions: {
      get() {
        return this.existingResults || [];
      },
      set(value) {
        this.$emit('update:existingResults', value);
      },
    },
    sortedQuestions() {
      return this.sortQuestions(this.questions);
    },
    orderOptions() {
      const options = [];
      for (let i = 0; i < this.questions.length; i += 1) {
        options.push(i + 1);
      }
      return options;
    },
  },
  methods: {
    getAssetUrl(slug) {
      return `${slug}`;
    },
    fileRemovedAction(index) {
      this.questions[index].uploader = null;
    },
    fileAddedAction(index) {
      this.questions[index].uploader = this.$refs.file1[index];
      // console.log(this.$refs.file1[index]);
      // Object.keys(this.questions).forEach((key) => {
      //   console.log(this.$refs.file1[key]);
      //   this.questions[key].resultImage = this.$refs.file1[key];
      // });
    },
    sortQuestions(questions) {
      return questions.slice().sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
    },
    addAnswerOption(index, type) {
      if (this.questions[index]) {
        this.questions[index].questionOptions.push(this.generateAnswers(type, this.questions[index].resultId));
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${index} was not found`,
        });
      }
    },
    removeAnswerOption(answerId, index) {
      if (this.questions[index]) {
        this.questions[index].questionOptions = this.questions[index].questionOptions.filter(
          (a) => a.answerId !== answerId,
        );
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The answer option with id ${answerId} was not found`,
        });
      }
    },
    removeQuestion(resultId, index) {
      if (this.questions[index]) {
        // update array to avoid any gaps in numbers. ex 1 3 5 -> 1 2 3
        const localQuestions = this.questions.filter((q) => q.resultId !== resultId);
        const sortedQuestions = this.sortQuestions(localQuestions);
        for (let i = 0; i < sortedQuestions.length; i += 1) {
          const questionIndex = localQuestions.findIndex(
            (question) => question.resultId === sortedQuestions[i].resultId,
          );
          localQuestions[questionIndex].order = i + 1;
        }
        this.questions = [...localQuestions];
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${index} was not found`,
        });
      }
    },
    addResult() {
      this.questions.push({
        resultId: `${uuidv4()}`,
        resultImage: '',
        uploader: null,
      });
    },
    changeOrder(changedQuestionCard) {
      const reorderedQuestions = [...this.sortedQuestions];
      const newIndex = changedQuestionCard.order - 1;
      const oldIndex = reorderedQuestions.findIndex((question) => question.resultId === changedQuestionCard.resultId);
      if (oldIndex > -1) {
        // Remove item from array
        reorderedQuestions.splice(oldIndex, 1);
        // Insert item at new index
        reorderedQuestions.splice(newIndex, 0, changedQuestionCard);
      }
      // console.log(reorderedQuestions);
      // take the indicies of the reordered array and assign those to the order key on the main array
      const localQuestions = [...this.questions];
      for (let i = 0; i < reorderedQuestions.length; i += 1) {
        // Find the reordered array element find where it is in the main array to update it's order key
        const questionIndex = localQuestions.findIndex(
          (question) => question.resultId === reorderedQuestions[i].resultId,
        );
        // assign as current reordered array key + 1 for a start at 1
        localQuestions[questionIndex].order = i + 1;
      }
      this.questions = [...localQuestions];
    },
    handleQuestionTypeChange(question, questionIndex) {
      this.$nextTick().then(() => {
        if (question && questionIndex !== undefined) {
          // Update questionTypeId
          question.questionTypeId = question.type.id;
          // Clear question options and answers on type change
          if (question.questionOptions && question.questionOptions.length) {
            this.questions[questionIndex].questionOptions = [];
          }
          if (question.questionAnswers && question.questionAnswers.length) {
            this.questions[questionIndex].questionAnswers = [];
          }
          this.questions[questionIndex].questionOptions = this.generateAnswers(question.type.key, question.resultId, 2);
          // }
        }
      });
    },
    // Returns single answer by default or many answers if question type is multiple choice
    /**
     * @param {string} questionType
     * @param {string} resultId
     * @param {number} _count
     * @returns {object|Array}
     */
    generateAnswers(_type, resultId, _count = 1) {
      const answers = [];
      // const type = _type.toLowerCase();
      let count = _count;

      while (count) {
        answers.push({
          text: '',
          resultId,
          answerId: `temp-${uuidv4()}`,
          isAnswer: false,
        });
        // }

        // }
        count -= 1;
      }
      return answers.length === 1 ? answers[0] : answers;
    },
    /**
     * Validate checkboxes and radio buttons before submission
     * @returns {boolean}
     */
    validateQuiz() {
      let isValid = true;
      this.questions.forEach((question) => {
        if (question.type.key === 'multiple choice') {
          const answerInput = this.$refs[question.resultId][0].querySelector('input[type="checkbox"]');
          if (!question.questionOptions.some((option) => option.isAnswer)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'At least one answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        } else if (question.type.key === 'single answer') {
          const answerInput = this.$refs[question.resultId][0].querySelector('input[type="radio"]');
          if (!question.questionOptions.some((option) => option.isAnswer)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'One answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        }
      });
      return isValid;
    },
    /**
     * Update an element's custom validity with a message.
     */
    updateElementValidity(element, message = '') {
      element.setCustomValidity(message);
    },
    /**
     * Finds input element based on resultId and className and updates it to valid.
     */
    updateInputsValid(resultId, className) {
      this.updateElementValidity(this.$refs[resultId][0].getElementsByClassName(className)[0]);
    },
    updateCheckbox(event) {
      if (event?.target) {
        this.updateElementValidity(event.target);
      }
    },
    /**
     * Updates a series of radio buttons to have a single radio input selected.
     */
    updateRadio(event, answer, resultId) {
      // Set checked radio answer to true
      answer.isAnswer = true;
      // Set all other answers to false
      this.questions
        .find((question) => question.resultId === resultId)
        ?.questionOptions?.forEach((option) => {
          if (option.answerId !== answer.answerId || option.id !== answer.id) {
            option.isAnswer = false;
          }
        });
      // Update validity
      if (event?.target) {
        this.updateElementValidity(event.target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-container {
  display: block;
  width: 100%;
  .question {
    padding: 40px;
    margin-bottom: 40px;
    background-color: rgba(#fff, 0.15);
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-row,
    .form-row-double {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .form-input {
        width: 100%;
      }

      .form-input-100 {
        width: 100%;
      }

      .form-input-90 {
        width: 90%;
      }

      .order {
        margin-right: 30px;
      }
      .form-input-10 {
        width: 10%;
      }
    }
    .wrap-row {
      flex-wrap: wrap;
    }
    .subtext {
      margin: 10px 0 0;
      margin-bottom: 15px;
      font-size: 12px;
      opacity: 0.5;
    }

    .answer-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      .answer-checkbox {
        margin-right: 30px;
      }
      .form-input {
        margin-bottom: -15px;
      }
      .remove-button {
        margin-left: 15px;
      }
    }

    .matching {
      flex-wrap: wrap;
      label {
        width: 100%;
      }

      .match-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;

        .form-input-45 {
          width: 45%;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .add-button {
    .button-icon {
      width: 20px;
      height: 20px;
    }
  }

  .add-button,
  .remove-button {
    display: flex;
    align-items: center;
    margin-top: 2em;
    font-size: 14px !important;
    letter-spacing: 0.7px;
    cursor: pointer;
    background: none;
    border: none;
  }

  .button-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 15px;
  }

  .input-spacing {
    margin-left: 20px;
  }
  .input-header {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .input-instructions {
    font-size: 14px;
  }
  .results-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 515px;
    margin-top: 1em;
    margin-bottom: 1.5em;
  }
  .upload-icon {
    width: 19px;
    height: 20px;
    opacity: 0.5;
    stroke: rgb(93, 93, 93);
  }
  .upload-container {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  ::v-deep .g-input .input-element:disabled {
    background-color: $primary-white;
  }
  ::v-deep .base-file-upload .select-file {
    align-items: center;
  }
}
</style>
