<template>
  <div class="examples">
    <PassportBreadcrumbs :parentRoute="this.$route.meta.parentComponent" :currentRoute="this.$route.name" />
    <span class="page-heading"> Edit Personality Quiz </span>
    <hr class="line-break" />
    <h2 class="category-heading">Personality Quiz Information</h2>
    <div class="quiz-info">
      <div>
        <span class="input-header"> Personality Quiz Title* </span>
        <PassportInput v-model="quizName" placeholder="Type your quiz name..." class="quiz-title" />
        <span
          :class="{ 'input-instructions': !isError || quizName, 'input-instructions__error': isError && !quizName }"
        >
          Give this personality quiz a title.
        </span>
      </div>
    </div>
    <div class="quiz-description">
      <span class="input-header"> description* </span>
      <PassportInput v-model="quizDescription" class="description-text" type="textarea" />
      <span
        :class="{
          'input-instructions': !isError || quizDescription,
          'input-instructions__error': isError && !quizDescription,
        }"
      >
        Add a description to this personality quiz.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Personality Quiz Setup: Results</h2>
    <QuizResultsPanel ref="questions" :existingResults.sync="existingResults" />
    <hr class="line-break" />
    <h2 class="category-heading">Personality Quiz Setup: Questions</h2>
    <QuizQuestionPanel ref="questions" :existingQuestions.sync="existingQuestions" />
    <hr class="line-break" />
    <div class="buttons-container">
      <PassportButton label="Save personality quiz" @click="errorValidation" />
      <PassportButton variant="text secondary" label="Cancel" @click="$router.back()" />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportInput from '@/components/PassportInput.vue';
import QuizResultsPanel from '@/components/QuizResultsPanel.vue';
import QuizQuestionPanel from '@/components/QuizQuestionPanel.vue';
import PassportBreadcrumbs from '@/components/PassportBreadcrumbs.vue';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@/services/api.service';

export default {
  name: 'default',
  components: { PassportInput, QuizResultsPanel, PassportButton, QuizQuestionPanel, PassportBreadcrumbs },
  props: {},
  data() {
    return {
      id: this.$route.params.id,
      quizName: '',
      quizDescription: '',
      isError: false,
      resultPresigned: '',
      existingQuestions: [
        {
          id: uuidv4(),
          questionId: uuidv4(),
          questionText: '',
          questionOptions: [],
        },
      ],
      existingResults: [
        {
          resultId: uuidv4(),
          resultTitle: '',
          resultDescription: '',
          resultImage: '',
          uploader: null,
        },
        {
          resultId: uuidv4(),
          resultTitle: '',
          resultDescription: '',
          resultImage: '',
          uploader: null,
        },
      ],
    };
  },
  created() {},
  computed: {},
  watch: {},
  mounted() {
    this.getExistingData();
  },
  methods: {
    async getExistingData() {
      await ApiService.get(`/quiz/personality/${this.id}`).then((response) => {
        this.quizName = response.data.name;
        this.quizDescription = response.data.description;
        this.existingResults = response.data.results;
        this.existingQuestions = response.data.questions.map((question, index) => ({
          order: index + 1,
          questionId: question.questionId,
          questionText: question.questionText,
          questionOptions: question.answers,
        }));
        this.existingQuestions.forEach((question) => {
          question.questionOptions.forEach((option) => {
            option.answerLead += 1;
          });
        });
      });
    },
    errorValidation() {
      const requests = [];
      Object.keys(this.existingResults).forEach((key) => {
        if (this.existingResults[key].uploader) {
          let { extension } = this.existingResults[key].uploader.file;
          if (extension === 'jpeg') {
            extension = 'jpg';
          }
          try {
            requests.push(
              ApiService.post(`/quiz/personality/${this.id}/asset`, {
                fileFormat: extension,
              }).then((response) => {
                const presignedUrl = this.existingResults[key].uploader;
                // presignedUrl.postS3(response.data.presignedUrl);
                this.existingResults[key].resultImage = response.data.assetUrl;
                this.results.push(response.data.assetUrl);
                return presignedUrl.postS3(response.data.presignedUrl);
              }),
            );
          } catch (err) {
            console.error(err);
          }
        }
      });
      Promise.all(requests)
        .then((promises) => {
          Promise.all(promises).then(() => {
            this.submitForm();
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      const quiz = {
        name: this.quizName,
        description: this.quizDescription,
        results: this.existingResults.map(({ resultId, resultTitle, resultDescription, resultImage }) => ({
          resultId,
          resultTitle,
          resultDescription,
          resultImage,
        })),
        questions: this.existingQuestions.map((question) => ({
          questionId: question.questionId,
          questionText: question.questionText,
          answers: question.questionOptions.map((answer) => ({
            answerId: answer.answerId,
            answerText: answer.answerText,
            answerLead: answer.answerLead - 1,
          })),
        })),
      };
      if (!this.quizName || !this.quizDescription) {
        this.isError = true;
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else {
        ApiService.on(422, () => {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: 'Please fill out the quiz & results fields and try to save again.',
          });
        });
        ApiService.put(`/quiz/personality/${this.id}`, quiz)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              this.$notify({
                group: 'primary',
                type: 'success',
                text: 'Personality quiz saved successfully.',
              });
              this.$router.push('/quizzes');
            } else {
              this.$notify({
                group: 'primary',
                type: 'error',
                text: 'Error saving personality quiz.',
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.category-heading {
  margin-bottom: 2em;
  font-size: 24px;
  letter-spacing: -0.6px;
}

.quiz-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width:90%;
}
.quiz-title {
  width: 515px;
  height: 50px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.input-header {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.input-instructions {
  font-size: 14px;
  &__error {
    font-size: 14px;
    color: $red;
  }
}
.radio-instructions {
  margin-left: 3.45em;
  font-size: 14px;
}
.quiz-description,
.hint-holder {
  margin-top: 2em;
}
.point-holder {
  display: flex;
  flex-direction: column;
}
.switch {
  margin-top: 1em;
  margin-bottom: 1em;
}
::v-deep .g-checkbox .label.after {
  margin-left: 20px;
}
::v-deep .passport-button.default.primary {
  display: flex;
  align-items: center;
  margin-right: 2em;
  font-size: 14px;
  text-transform: uppercase;
}
.buttons-container {
  display: flex;
}
.quiz-type,
.radio-container {
  margin-top: 2em;
}
::v-deep .g-radio .label.after {
  padding: 20px;
}
::v-deep .passport-breadcrumbs {
  width: 20em;
}
.radio-parts {
  margin-bottom: 1em;
}

// .description-text {
//   width: 1061px;
//   height: 150px;
// }
</style>
